import { Config, ErrorResponse } from '@typings/generic'
import { ERROR_BACKEND_MAPPING } from './errors'
import GenericFetch from 'framework/genericfetch'

/**
 * Fetching API class.
 * Please use it only for OAuth interface.
 * Avoid to Enrich it with other API. Create your own fetching class if needed
 */
export default class OauthFetch extends GenericFetch {
    constructor(config: Config, name: string) {
        super(config, (name || 'oauth').toLowerCase())
        this.registerErrors(ERROR_BACKEND_MAPPING)
    }

    /**
     * Parses several form of error messaging.
     * This method needs to be redefined by the children class
     * @param {Object} response
     * @returns {Object} '{ error: 'CODE', description: 'DESCRIPTION' }'
     */
    extractError(response: ErrorResponse) {
        if (response.error) {
            return {
                error: response.error,
                description: response.error_description,
            }
        }
        if (response?.operationError) {
            return {
                error: response?.operationError?.code,
                description: response?.operationError?.message,
            }
        }

        return { error: 'unknown', description: 'unknown' }
    }
}
