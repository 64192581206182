const dcmcommon = {
    clientId: 'm23726',
    supportedLanguages: ['en'],
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attus.m1amdocs.com/',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: '',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        apiUrl: 'https://attusdcmtest01-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attusdcmtest01-ulm-pdt.m1amdocs.io',
        redirectUrl: 'https://waf-attus-dcm-pre001.ifs.vubiquity.com/oauthredirect',
        userPreferencesUrl: 'https://attusdcmtest01-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attusdcmtest01-ulm-pdt.m1amdocs.io/storefront',
    },
    OAuthParam: {
        scope: 'openid profile storefront customData',
        grantType: 'authorization_code',
        requestMode: 'form_post',
        endpoint: {
            auth: '/oidc/authorize',
            token: '/oidc/token',
            refreshToken: '/oauth/token',
        },
        extras: {
            response_mode: 'form_post',
        },
        debugMode: true,
        forcePollingIntervalSecs: 10,
    },
    proxyVersion: 3,
    encryption: {
        enabled: true,
        passphrase: 'mY5}vH9%pN0!dW7{xI7$aG8{iK8}aT2/',
    },
    marketone: {
        skipRefreshToken: true,
        enableUserPreferencesApi: true,
        apiVersion: 'V2',
        retrieveOnlyActiveSubscriptions: false,
    },
}

export default dcmcommon
