import VoltError from 'VoltError'

/**
 * Mapping Error Table from the Backend
 */
export const ERROR_BACKEND_MAPPING = {
    /* ------------------------------ [SECTION] STANDARD ERROR ------------------------------ */
    /* The authorization request is still pending as the end user hasn't yet completed the user interaction steps 
            The client SHOULD repeat the Access Token Request to the token endpoint.  Before each new request the client MUST 
            wait at least the number of seconds specified by the "interval" parameter of the Device Authorization Response */
    authorization_pending: () => [
        VoltError.codes.AUTH_OAUTH_WAITING_LOGIN_WITH_SECONDARY_DEVICE,
        'A',
    ],
    /* A variant of "authorization_pending", the authorization request is still pending and polling should 
            continue, but the interval MUST  be increased by 5 seconds for this and all subsequent requests. */
    slow_down: () => [VoltError.codes.AUTH_OAUTH_SLOW_DOWN_POLLING, 'A'],
    /* The "device_code" has expired and the device authorization session has concluded. The client MAY commence a new 
            Device Authorization Request but SHOULD wait for user interaction before restarting to avoid unnecessary polling.*/
    expired_token: () => [VoltError.codes.AUTH_OAUTH_EXPIRED_TOKEN, 'A'],
    invalid_grant: () => [VoltError.codes.AUTH_INVALID_GRANT, 'A'],
    invalid_client: () => [VoltError.codes.AUTH_INVALID_CONFIG, 'A'],
    invalid_scope: () => [VoltError.codes.AUTH_INVALID_CONFIG, 'B'],
    unsupported_grant_type: () => [VoltError.codes.AUTH_INVALID_CONFIG, 'C'],
    unauthorized_client: () => [VoltError.codes.AUTH_INVALID_CONFIG, 'D'],
    invalid_token: (error: string) => {
        if (error && error.toLowerCase() === 'token has expired') {
            return [VoltError.codes.AUTH_OAUTH_EXPIRED_TOKEN, 'B']
        }
        return [VoltError.codes.INVALID_AUTH_TOKEN, 'A']
    },
    /* ------------------------------ [END OF SECTION] STANDARD ERROR ------------------------------ */
    /* ------------------------------ [SECTION] SPECIFIC ERROR ------------------------------ */
    unauthorized_device_id: () => [VoltError.codes.AUTH_UNAUTHORIZED_USER, 'A'],
    /* ------------------------------ [END OF SECTION] SPECIFIC ERROR ------------------------------ */

    /* #region ------------------------------ UFINITY OAUTH ERRORS ------------------------------ */
    bad_request: () => [VoltError.codes.HTTP_400_BAD_REQUEST, 'A'],
    invalid_code: () => [VoltError.codes.FAILED_QR_CODE_VALIDATION_INVALID_CODE, 'A'],
    /* #endregion ------------------------------ UFINITY OAUTH ERRORS ------------------------------ */
    'invalid-act-consumption': () => [VoltError.codes.AUTH_IDENTIFIER_OTP_INVALID],
    'action-token-expired': () => [VoltError.codes.AUTH_IDENTIFIER_OTP_EXPIRED],
    'action-token-retries-exceeded': () => [VoltError.codes.AUTH_IDENTIFIER_OTP_RETRIES_EXECEEDED],
}
