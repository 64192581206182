import config from './dcmcommon.js'

const conf = {
    ...config,
    env: 'dcm_oac5',
    clientId: 'ea3c9305d32648a682b72ba379a76a87',
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attusdcmtest05-ulm-pdt.m1amdocs.io',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: '',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://attusdcmtest05-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attusdcmtest05-ulm-pdt.m1amdocs.io',
        redirectUrl: 'https://waf-attus-dcm-oac-pre005.ifs.vubiquity.com/oauthredirect',
        userPreferencesUrl: 'https://attusdcmtest05-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attusdcmtest05-ulm-pdt.m1amdocs.io/storefront',
        extensionApiEndPoint: 'https://plm-prodtest.m1amdocs.io/el/dcm/pdt5',
    },
    marketone: {
        skipRefreshToken: true,
        enableUserPreferencesApi: true,
        customDatas: [],
    },
}

export default conf
