import CloseImport from "./Close.tsx";
import ErrorImport from "./Error.tsx";
import HeaderImageImport from "./HeaderImage.tsx";
import InformationImport from "./Information.tsx";
import SuccessImport from "./Success.tsx";
import WarningImport from "./Warning.tsx";
import faviconImport from "./favicon.ico";

const clientAssets = {
    'Close': CloseImport,
    'Error': ErrorImport,
    'HeaderImage': HeaderImageImport,
    'Information': InformationImport,
    'Success': SuccessImport,
    'Warning': WarningImport,
    'favicon': faviconImport,
}
export default clientAssets
