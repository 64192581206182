import {lazy} from 'solid-js'

export * from './config/useConfig'
export * from './toast/useToast'
export * from './icon/useIcon'
export * from './theme/useTheme'
export * from './theme/emiTheme'

export const AppBootstrap = lazy(() => import('./AppBootstrap'))
export const AppProviders = lazy(() => import('./AppProviders'))
export const Toast = lazy(() => import('./toast/Toast'))
export const Spinner = lazy(() => import('./spinner/Spinner'))
export const LoadingScreen = lazy(() => import('./loadingScreen/LoadingScreen'))
export const ThemeProvider = lazy(() => import('./theme/ThemeProvider'))
export const Button = lazy(() => import('./button/Button'))
export const DateDropdown = lazy(() => import('./dateDropdown/DateDropdown'))
export const Footer = lazy(() => import('./footer/Footer'))
export const Header = lazy(() => import('./header/Header'))
export const HeaderLogo = lazy(() => import('./HeaderLogo/Logo'))
export const Input = lazy(() => import('./input/Input'))
export const Label = lazy(() => import('./label/Label'))
export const SidebarMenu = lazy(() => import('./sidebarMenu/SidebarMenu'))
