import config from './dcmcommon.js'

const conf = {
    ...config,
    env: 'dcm_oac4',
    clientId: '9d3899ee1f1c4922906cf60d97a6dac1',
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attusdcmtest04-ulm-pdt.m1amdocs.io',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: '',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://attusdcmtest04-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attusdcmtest04-ulm-pdt.m1amdocs.io',
        redirectUrl: 'https://waf-attus-dcm-oac-pre004.ifs.vubiquity.com/oauthredirect',
        userPreferencesUrl: 'https://attusdcmtest04-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attusdcmtest04-ulm-pdt.m1amdocs.io/storefront',
        extensionApiEndPoint: 'https://plm-prodtest.m1amdocs.io/el/dcm/pdt4',
    },
}

export default conf
