import {render} from 'solid-js/web'
import {configureStoreFactory} from '@ifs/volt-store'
import './client/styles/color.css'
import './client/styles/font.css'
import {Navigate, useNavigate} from '@solidjs/router'
import {lazy} from 'solid-js'
import icons from 'client-assets'
import voltApi from 'client-api'
import './libs/foundation'
import './index.css'
import {AppBootstrap} from './libs/foundation'
import {ToastPosition} from './libs/foundation/components/toast/Toast'
import config from './config'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

const root = document.getElementById('root')

const {UNKNOWN_ERROR, UNKNOWN_API_ERROR, LOGIN_REQUIRED, PLAYER_ENTITLEMENT_REVOKED} = voltApi.VoltError.codes
const ignoredNotification = [
    // Filter out LOGIN_REQUIRED -> shall be handled by to restart starter Kit
    LOGIN_REQUIRED.code,

    // Filter out PLAYER_ENTITLEMENT_REVOKED because this error triggers a purchasing channel flow (channel not entitled)
    PLAYER_ENTITLEMENT_REVOKED.code,
    // Filter out generic errors (not précise enough to display coherent information message)
    config.env !== 'development' && UNKNOWN_ERROR.code,
    config.env !== 'development' && UNKNOWN_API_ERROR.code,
]

render((): any => {
    return (
        <AppBootstrap
            ignoredNotification={ignoredNotification}
            defaultConfig={config}
            storeOptions={{
                voltApi,
                configureStoreFactory,
            }}
            toastPosition={ToastPosition.TOP_CENTER}
            icons={icons}
            routes={[
                {
                    path: '/',
                    children: [
                        {
                            path: '/',
                            component: () => <Navigate href={'/dashboard'} />,
                        },
                        {
                            path: '/dcm',
                            component: lazy(() => import('./libs/components/login/Login')),
                        },
                        {
                            path: '/login',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(() => import('./libs/components/login/Login')),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/dashboard',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(() => import('./libs/components/homePage/HomePage')),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/oauthredirect',
                            component: lazy(() => import('./libs/foundation/authorization/AuthRedirect')),
                        },
                        {
                            path: '/auth',
                            component: lazy(() => import('./libs/components/layout/Layout')),
                            children: [
                                {
                                    path: '', // Matches '/auth'
                                    component: lazy(() => import('./libs/components/otpPage/OtpPage')),
                                },
                            ],
                        },
                        {
                            path: '/cancelSubscription',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(
                                                () => import('./libs/components/cancelSubscription/CancelSubscription')
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/purchaseDetail',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(
                                                () => import('./libs/components/purchaseDetails/PurchaseDetails')
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            path: '/customerrefund',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(
                                                () => import('./libs/components/customerRefund/CustomerRefund')
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/logout',
                            component: lazy(() => import('./App')),
                            children: [
                                {
                                    path: '/',
                                    component: lazy(() => import('./libs/components/layout/Layout')),
                                    children: [
                                        {
                                            path: '/',
                                            component: lazy(() => import('./libs/components/login/Login')),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '*',
                            component: lazy(() => import('./libs/components/notFound/NotFound')),
                        },
                    ],
                },
            ]}
        />
    )
}, root!)
