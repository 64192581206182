import commonConfig from './common.js'
import { changeEnv } from './env.js'
import dcm_oac1 from './dcm_oac1.js'
import dcm_oac2 from './dcm_oac2.js'
import dcm_oac3 from './dcm_oac3.js'
import dcm_oac4 from './dcm_oac4.js'
import dcm_oac5 from './dcm_oac5.js'

const apiConfig = (() => {
    let env = 'dcm_oac1'
    try {
        env = changeEnv()
    } catch (e) {
        throw new Error('No environment specified')
    }

    switch (env) {
        case 'dcm_oac1':
            return dcm_oac1
        case 'dcm_oac2':
            return dcm_oac2
        case 'dcm_oac3':
            return dcm_oac3
        case 'dcm_oac4':
            return dcm_oac4
        case 'dcm_oac5':
            return dcm_oac5
        default:
            throw new Error('unknown env: ' + env)
    }
})()

const allApiConfig = {
    dcm_oac1,
    dcm_oac2,
    dcm_oac3,
    dcm_oac4,
    dcm_oac5,
}
export default { ...commonConfig, ...apiConfig, allApiConfig }
