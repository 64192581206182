import {getBrowserName} from './platform'

const isSafari = (voltApi: any) => {
    return getBrowserName(voltApi) === voltApi.Constants.platform.safari
}

export const getManifestType = (voltApi: any): string => {
    return isSafari(voltApi) ? 'application/x-mpegURL' : 'application/dash+xml'
}

export const getProfileName = (voltApi: any): string => {
    return isSafari(voltApi) ? 'com.apple.fairplay' : 'com.widevine.alpha'
}

export const getDRMSystem = (voltApi: any): string => {
    return isSafari(voltApi) ? 'nagra-ssp-fps' : 'nagra-ssp'
}
